//@import url("http://fonts.googleapis.com/css?family=Nunito:400,700");
//@import url(http://db.onlinewebfonts.com/c/07f38d7f5d5f95f0530e876474ab6141?family=Volkswagen-Bold);
//@import url(http://db.onlinewebfonts.com/c/e81d133554ce4dca06017efe695a6098?family=Volkswagen-DemiBold);
//@import url(http://db.onlinewebfonts.com/c/6fb4ace120a507d6086e669c62f1086e?family=Volkswagen-MediumIta);

@font-face {
  font-family: Nunito;
  src: url("../fonts/Nunito-Regular.ttf"); /* IE */
  src: local('Nunito'), url('../fonts/Nunito-Regular.ttf') format('truetype'); /* others */
}

@font-face {
  font-family: Volkswagen-Bold;
  src: url("../fonts/Volkswagen-Bold.ttf"); /* IE */
  src: local('Volkswagen-Bold'), url('../fonts/Volkswagen-Bold.ttf') format('truetype'); /* others */
}

@font-face {
  font-family: Volkswagen-DemiBold;
  src: url("../fonts/Volkswagen-DemiBold.ttf"); /* IE */
  src: local('Volkswagen-DemiBold'), url('../fonts/volkswagen-demibold.ttf') format('truetype'); /* others */
}

@font-face {
  font-family: Volkswagen-MediumIta;
  src: url("../fonts/Volkswagen-MediumIta.ttf"); /* IE */
  src: local('Volkswagen-MediumIta'), url('../fonts/Volkswagen-MediumIta.ttf') format('truetype'); /* others */
}

.btn:hover {
  /* here copy default .btn class styles */
  cursor:default !important;
  /* or something like that */
  color: white !important;
}

@font-face {
  font-family: volkswagen-light;
  src: url("../fonts/volkswagen-light.ttf"); /* IE */
  src: local('volkswagen-light'), url('../fonts/volkswagen-light.ttf') format('truetype'); /* others */
}

@font-face {
  font-family: VolkswagenHBold;
  src: url("../fonts/VolkswagenH Bold.ttf"); /* IE */
  src: local('VolkswagenH Bold'), url('../fonts/VolkswagenH Bold.ttf') format('truetype'); /* others */
}

@font-face {
  font-family: vb;
  src: url("../fonts/vb.ttf"); /* IE */
  src: local('vb'), url('../fonts/vb.ttf') format('truetype'); /* others */
}
@font-face {
  font-family: Volkswagen-Regular;
  src: url("../fonts/Volkswagen-Regular.ttf"); /* IE */
  src: local('Volkswagen-Regular'), url('../fonts/Volkswagen-Regular.ttf') format('truetype'); /* others */
}

@media (min-width: 1200px) {
  .container { width:1280px; }
}

.row {
    margin-right: 0;
    margin-left: 0;
}

.col-md-12 {
    padding-right:0;
    padding-left:0;
}

* {
  user-select: none;
  font-family: 'VolkswagenHBold', "Nunito";
}

body { margin:0; color:black; }

#language{ visibility: hidden; }
.show-cubee{ display: none;  }

.helpbutton {
  width: 121px;
  height: 121px;
  border-radius: 50%;
  background-color: #af001e;
  border: 4px solid #fff;
  box-shadow: 0 0 0 5px #af001e;
  -ms-transform: rotate(-35deg); /* IE 9 */
  -webkit-transform: rotate(-35deg); /* Chrome, Safari, Opera */
  transform: rotate(-35deg);
  padding: 18px;
  font-size: 52px;
  color: #FFF;
  cursor: pointer;
}

@media (max-width: 1024px){
  body {
    transform: scale(0.75);
    transform-origin: 0 0;
  }

  html {
    width: 1366px;
    overflow: hidden;
  }

  .header {
    background: #fff url("../images/deburen_graphics/header_be_6_1.png") no-repeat center top!important; height: 210px!important;
  }

  #main {
    background-color: white!important;
  }
  #watermark_disabled {

    background: #fff url("../images/deburen_graphics/watermark_3.png") no-repeat right bottom;
    khtml-opacity:.25;
    -moz-opacity:.25;
    -ms-filter:"alpha(opacity=25)";
    filter:alpha(opacity=25);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.25);
    opacity:.25;
  }

  .helpbutton {
    width: 121px;
    height: 121px;
    border-radius: 50%;
    background-color: #da0719;
    border: 4px solid #fff;
    box-shadow: 0 0 0 5px #da0719;
    -ms-transform: rotate(-35deg); /* IE 9 */
    -webkit-transform: rotate(-35deg); /* Chrome, Safari, Opera */
    transform: rotate(-35deg);
    padding: 18px;
    font-size: 52px;
    color: #FFF;
    cursor: pointer;
  }

  .informationHeader{
    font-size: 33px!important;
    line-height: 33px!important;
  }

  .information b {
    font-size:22px!important;
    line-height: 22px!important;
  }

  .information {
    font-size:22px!important;
    line-height: 22px!important;
  }

  .breadcrumbdb {
    background-color: #8e9293!important;
    /*border: 1px solid #af001e!important;*/
    border: none!important;
    margin-top: 2px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .breadcrumbdb li a {
    background-color: #8e9293!important;
  }

  .breadcrumbdb li a::before {
    border-left: 30px solid #fff!important;
    margin-top: -52px!important;
  }

  .breadcrumbdb li a::after {
    border-left: 30px solid #8e9293!important;
    margin-top: -52px!important;
    margin-left: -1px!important;
  }

  .breadcrumbdb li:last-child a::before {
    border-left: 30px solid #8e9293!important;
    margin-top: -52px!important;
  }

  .breadcrumbdb li a.active {
    background: #646363 none repeat scroll 0 0!important;
  }

  .breadcrumbdb li a.active::before {
    border-left: 30px solid #fff!important;
    margin-top: -52px!important;
  }

  .breadcrumbdb li a.active::after {
    border-left: 30px solid #646363!important;
    margin-top: -52px!important;
    margin-left: -1px!important;
  }

  .breadcrumbdb li:first-child a::after {
    border-bottom: 51px solid transparent!important;
    border-top: -1px solid transparent!important;
  }

  .breadcrumbdb li:first-child a.active::after {
    border-bottom: 52px solid transparent!important;
    border-left: 30px solid #475155;
    border-top: 52px solid transparent;
  }

  .bubble {
    box-shadow: 0 0 5px 4px rgba(50, 50, 50, 0.75)!important;
  }

  .btm-right-in::after{
    box-shadow: 11px 8px 1px -8px rgba(50, 50, 50, 0.75) !important
  }

  #language{
    right: 10% !important;
    visibility: visible;
  }

  #language a{
    border-color: #c6c6c6;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    font-size: 32px;
    color: #999999;
    padding: 10px;
  }

  #language a.active {
    /*box-shadow: 0px 10px 10px 2px #c6c6c6;*/
    border-color: #c6c6c6;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    /*border-width: 8px 2px 10px;*/
    background: #c6c6c6;
    font-size: 32px;
    color: #fff;
    padding: 10px;
  }

  .lockerChoose{
    width: 82%!important;
  }

  .show-bighead{ display: none; }
  .show-deburen{ display: none; }
  .show-cubee{ display: block;  }

  .keypad{ width: 82%!important;}

  .btn-buren1 {
    text-align:left;
    background-color:#da0719!important;
    color:white;
    border-radius:20px;
    box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
    inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
    width: 80%;
    padding: 15px 15px 15px 30px;
    margin-bottom: 15px;
    font-family: 'Volkswagen-DemiBold', "Nunito";
  }
  .btn-buren2 {
    text-align:left;
    background-color:#da0719!important;
    color:white;
    border-radius:20px;
    box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
    inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
    margin-left: 25px;
  }
  .btn-footer-left {
    text-align:left;
    background-color:#da0719!important;
    color:white;
    border-radius:20px;
    box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
    inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
    margin-right: 25px;
    margin-top: -15px;
  }

  .btn-footer-right {
    text-align:left;
    background-color:#da0719!important;
    color:white;
    border-radius:20px;
    box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
    inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
    margin-left: 25px;
    margin-top: -15px;
  }

  .btn-footer-left .glyphicon, .btn-footer-right .glyphicon {
    top:14px;
  }


  .btn-buren3 {
    text-align:left;
    background-color:#da0719!important;
    color:white;
    border-radius:15px;
    box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
    inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
  }
  .btn-buren4 {
    text-align:left;
    background-color:#da0719!important;
    color:white;
    border-radius:20px!important;
    box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
    inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
  }
  .btn-buren5 {
    text-align:center;
    background-color:#da0719!important;
    color:white;
    border-radius:20px;
    box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
    inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
    width: 100%;
    padding: 15px 15px 15px 15px;
    margin-bottom: 15px;
    font-family: 'Volkswagen-DemiBold', "Nunito";
  }

  .btn-buren1 .glyphicon,
  .btn-buren2 .glyphicon,
  .btn-buren3 .glyphicon,
  .btn-buren4 .glyphicon {
    top:12px;
  }

  footerburen{ background: #FFF!important;}


}

// Uhm this is a copy from above, because well we need it to work with other resolutions for now.
// TODO: maybe delete this, our rethink the above one?

body.force-color {

  #main {
    background-color: white!important;
  }
  .breadcrumbdb {
    background-color: #af001e!important;
    /*border: 1px solid #af001e!important;*/
    border: none!important;
    margin-top: 2px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .breadcrumbdb li a {
    background-color: #af001e!important;
  }

  .breadcrumbdb li a::before {
    border-left: 30px solid #fff!important;
    margin-top: -52px!important;
  }

  .breadcrumbdb li a::after {
    border-left: 30px solid #af001e!important;
    margin-top: -52px!important;
    margin-left: -1px!important;
  }

  .breadcrumbdb li:last-child a::before {
    border-left: 30px solid #af001e!important;
    margin-top: -52px!important;
  }

  .breadcrumbdb li a.active {
    background: #666666 none repeat scroll 0 0!important;
  }

  .breadcrumbdb li a.active::before {
    border-left: 30px solid #fff!important;
    margin-top: -52px!important;
  }

  .breadcrumbdb li a.active::after {
    border-left: 30px solid #666666!important;
    margin-top: -52px!important;
    margin-left: -1px!important;
  }

  .breadcrumbdb li:first-child a::after {
    border-bottom: 51px solid transparent!important;
    border-top: -1px solid transparent!important;
  }

  .breadcrumbdb li:first-child a.active::after {
    border-bottom: 52px solid transparent!important;
    border-left: 30px solid #666666;
    border-top: 52px solid transparent;
  }

  .bubble {
    box-shadow: 0 0 5px 4px rgba(50, 50, 50, 0.75)!important;
  }

  .btm-right-in::after{
    box-shadow: 11px 8px 1px -8px rgba(50, 50, 50, 0.75) !important
  }


}

.unselectable {
  -WEBKIT-USER-SELECT: NONE; /* SAFARI, CHROME */
  -KHTML-USER-SELECT: NONE; /* KONQUEROR */
  -MOZ-USER-SELECT: NONE; /* FIREFOX */
  -MS-USER-SELECT: NONE; /* IE */
  USER-SELECT: NONE; /* CSS3 */
}

#countrypicker {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-control[disabled]{
  background-color: #E5E5E5;
}

h1 {
  font-size:45px;
  /*font-weight:700;*/
  /*margin-top:50px;*/
  margin-bottom: 25px;
  letter-spacing: 2px;
  line-height: 54px;
}

h2 {
  font-size:36px;
  font-weight:700;
  margin-top:0;
}

ul {
  font-size:36px;
}

.breadcrumbdb {
  list-style: none;
  overflow: hidden;
  font: 18px Helvetica, Arial, Sans-Serif;
  border: 1px solid #C3A802;
  border-radius: 10px;
  padding-left: 0px;
  background: #C3A802;
  margin-top: 2px;
  margin-left: 15px;
  margin-right: 15px;
}
.breadcrumbdb li {
  float: left;
}
.breadcrumbdb li a {
  color: white;
  text-decoration: none;
  padding: 10px 10px 10px 50px;
  background: #C3A802;                   /* fallback color */
  /*background: hsla(34,85%,35%,1);*/
  position: relative;
  display: block;
  float: left;
  font-size: 30px;
  /*letter-spacing: 1px;*/
  line-height: 36px;
}

.breadcrumbdb li a.active {
  color: white;
  text-decoration: none;
  padding: 10px 10px 10px 50px;
  background: #666666;                   /* fallback color */
  /*background: hsla(34,85%,35%,1);*/
  position: relative;
  display: block;
  float: left;
  font-size: 30px;
  /*letter-spacing: 1px;*/
  line-height: 36px;
}

.breadcrumbdb li a.active:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid rgb(230,199,7);
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}

.breadcrumbdb li a.active:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid rgb(102,102,102);
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.breadcrumbdb li a:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid rgb(195,168,2);
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.breadcrumbdb li a:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid rgb(230,199,7);
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}

.breadcrumbdb li:first-child a {
  /*padding-left: 10px;*/
  padding: 10px 10px 10px 25px;
}
.breadcrumb li:nth-child(2) a       { background:        hsla(34,85%,45%,1); /*padding: 10px 10px 10px 50px;*/ }
.breadcrumb li:nth-child(2) a:after { border-left-color: hsla(34,85%,45%,1); }
.breadcrumb li:nth-child(3) a       { background:        hsla(34,85%,55%,1); /*padding: 10px 10px 10px 50px;*/ }
.breadcrumb li:nth-child(3) a:after { border-left-color: hsla(34,85%,55%,1); }
.breadcrumb li:nth-child(4) a       { background:        hsla(34,85%,65%,1); /*padding: 10px 10px 10px 50px;*/ }
.breadcrumb li:nth-child(4) a:after { border-left-color: hsla(34,85%,65%,1); }
.breadcrumb li:nth-child(5) a       { background:        hsla(34,85%,75%,1); /*padding: 10px 10px 10px 50px;*/ }
.breadcrumb li:nth-child(5) a:after { border-left-color: hsla(34,85%,75%,1); }
.breadcrumbdb li:last-child a:after {
  border-bottom: 0px solid transparent;
  border-left: 0px solid white;
  width: 500% !important;
}
.breadcrumbdb li:last-child a:before {
  border-bottom: 0px solid transparent;
  border-left: 0px solid white;
}

.breadcrumbdb li:last-child a.active {
  width: 1000%;
}

.breadcrumbdb li:first-child a.active {
  width: 100%!important;
}

.breadcrumbdb li:first-child a.active:after {
  border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid rgb(102,102,102);;
}
.breadcrumbdb li:first-child a.active:before {
  border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid rgb(102,102,102);
}

p {
  /*font-family: 'Volkswagen-DemiBold', "Nunito";*/
  font-size:35px;
  /*font-weight:400;*/
}

.title-buren1{
  margin-top: 60px;
  margin-bottom: 30px;
  font-weight: bold;
}

.txt-buren3{
  margin-top: 30px;
  margin-bottom: 30px;
}

.txt-buren1-welcome{
  font-family: 'Volkswagen-DemiBold', "Nunito";
  font-size:30px;
  padding-left: 15px;
  margin-top: 15px;
  /*margin-bottom: 30px;*/
}

.txt-buren1-normal{
  font-family: 'VolkswagenHBold', "Nunito";
  font-size:35px;
  padding-left: 15px;
  line-height: 42px;
}

.txt-buren1-normal b{
  font-size:32px;
  line-height: 35px;
}

.txt-buren1-parcels{
  font-family: 'Volkswagen-MediumIta', "Nunito";
  font-size:30px;
  padding-left: 15px;
  line-height: 35px;
}

h2.txt-buren1-seller {
  font-family: 'Volkswagen-Bold', "Nunito";
  font-size:30px;
  padding-left: 15px;
  font-weight: normal;
}

.txt-buren1-seller{
  font-family: 'Nunito', "Nunito";
  font-size:20px;
  padding-left: 15px;
  font-weight: normal;
}

.txt-buren1-view{
  font-family: "Nunito", 'Volkswagen-MediumIta' !important;
  font-size:20px !important;
}
.txt-buren2-view{
  font-family: 'Volkswagen-MediumIta', "Nunito" !important;
  font-size:30px !important;
  line-height: 30px !important;
}
.txt-buren1-view1{
  font-family: "Nunito", 'Volkswagen-MediumIta' !important;
  font-size:20px !important;
  font-weight: bold;
}

label.countrycode,
label.mobilenr,
label.customercode { font-size:30px; font-weight: normal; /*font-family: 'Volkswagen-DemiBold', "Nunito";*/ }
.footer-button { font-size:40px; margin-left: 15px; margin-right: 15px; margin-bottom: 5px;}
.anonymous-button { margin-left: 24px; font-size: 24px; }

button {
  font-size:32px !important;
  /*font-weight:700 !important;*/
  line-height: 48px;
}

input[type=text] {
  /*height:65px;*/
  /*font-size:36px !important;*/
}

.text{
  margin-top: 50px;
}

.choose {
  font-size:28px;
  font-weight: normal;
  margin-top: 50px;
}

.choose b {
  font-size:35px;
  font-weight: lighter;
}

.choose span {
  color: #99001a;
  font-family: 'Volkswagen-MediumIta', "Nunito";
}

.choose span {
  color: #99001a;
}

.text p {
  font-size:35px; font-weight: normal; font-family: 'Volkswagen-DemiBold', "Nunito"; letter-spacing: normal; width: 650px;
}

.overview td {
  font-size: 28px;
  border-bottom: 2px dotted #000;
  line-height: 33px;
  width: 250px;
  height: 80px;
}

.overview td:nth-child(2) {
  color: #af001e;
  font-size: 28px;
  border-bottom: 2px dotted #000;
  line-height: 33px;
  width: 650px;
}

.informationHeader{
  font-size: 30px;
  line-height: 30px;
  letter-spacing: normal;
  margin-bottom: 0;
}

.information b {
  font-size:24px;
  line-height: 24px;
}

.information {
  font-size:18px;
  line-height: 20px;
}

.text h1 {
  width: 650px;
}

.txt-buren1 {}
.txt-buren2 {}

.btn-buren1 {
  text-align:left;
  background-color:#af001e;
  color:white;
  border-radius:20px;
  box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
  inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
  width: 80%;
  padding: 15px 15px 15px 30px;
  margin-bottom: 15px;
  font-family: 'Volkswagen-DemiBold', "Nunito";
}
.btn-buren2 {
  text-align:left;
  background-color:#af001e;
  color:white;
  border-radius:20px;
  box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
  inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
  margin-left: 25px;
}
.btn-footer-left {
  text-align:left;
  background-color:#af001e;
  color:white;
  border-radius:20px;
  box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
  inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
  margin-right: 25px;
  margin-top: -15px;
}

.btn-footer-right {
  text-align:left;
  background-color:#af001e;
  color:white;
  border-radius:20px;
  box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
  inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
  margin-left: 25px;
  margin-top: -15px;
}

.btn-footer-left .glyphicon, .btn-footer-right .glyphicon {
  top:14px;
}


.btn-buren3 {
  text-align:left;
  background-color:#af001e;
  color:white;
  border-radius:15px;
  box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
  inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
}
.btn-buren4 {
  text-align:left;
  background-color:#af001e;
  color:white;
  border-radius:20px!important;
  box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
  inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
}
.btn-buren5 {
  text-align:center;
  background-color:#af001e;
  color:white;
  border-radius:20px;
  box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
  inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
  width: 100%;
  padding: 15px 15px 15px 15px;
  margin-bottom: 15px;
  font-family: 'Volkswagen-DemiBold', "Nunito";
}

.btn-buren1 .glyphicon,
.btn-buren2 .glyphicon,
.btn-buren3 .glyphicon,
.btn-buren4 .glyphicon {
  top:12px;
}

.note-small { font-size:30px; font-style:italic; }

.footer {
  vertical-align:middle; line-height:175px;
  &.left { float:left; }
  &.right { float:right; }
}

//.controller { position:relative; }
//.controller > .row { margin-top:50px; }
//.controller .footer { position:absolute; top:600px; right:0px; height:175px; line-height:175px; }
//.controller .footer.left { left:0px; }
//.controller .footer.right { right:0px; }
//.controller .accent { color: #af001e; }

div.well p { font-size:26px; line-height:32px; }

label.small { font-weight:normal; font-size:26px; font-family: 'Volkswagen-MediumIta', "Nunito"; }
span.small { font-weight:normal; font-size:25px !important; font-family: 'Volkswagen-MediumIta', "Nunito"; }

.btn-lg { height:80px; }

.btn-keyboard { width:70px; height:70px; }
.btn-keyboard-15 { width:107px; height:70px; font-size:12pt !important; }
.btn-keyboard-20 { width:145px; height:70px; }
.btn-keyboard-dot { width:59px; height:70px; }
.btn-keyboard-nl { width:65px; height:70px; font-size:26px !important; }
.btn-keyboard-com { width:86px; height:70px; font-size:26px !important; }
.keyboard .btn, .num .btn {
  border:0;
  border-radius:10px;
  box-shadow: inset 0px -2px 2px rgba(0,0,0,0.125), /*bottom internal shadow*/
  inset 0px 2px 2px rgba(255,255,255,0.125); /*top internal highlight*/
  background-color: #666;
  color: #fff;
  /*margin-bottom: 5px;*/
}

.keyboard .btn:active, .btn-numeric:active  {
  background-image: none;
  box-shadow: 0 3px 5px rgba(0,0,0,0.125) inset!important;
}

.btn.disabled{
  background-color: #ccc;
}

.keyboard-fluid .btn-keyboard { width:7.9%; height:70px; }
.keyboard-fluid .btn-keyboard-15 { width:12.2%; height:70px; font-size:14pt !important; }
.keyboard-fluid .btn-keyboard-20 { width:16%; height:70px; }
.keyboard-fluid .btn-keyboard-dot { width:6.4%; height:70px; }
.keyboard-fluid .btn-keyboard-nl { width:8.0%; height:70px; font-size:26px !important; }
.keyboard-fluid .btn-keyboard-com { width:10.0%; height:70px; font-size:26px !important; }

.btn-numeric { width:95px; height:72px; margin-bottom: 5px; }
.btn-numeric-15 { width:195px; height:72px; font-size:18pt !important; margin-bottom: 5px; }

.numeric-fluid .btn-numeric { width:8.0%; height:70px; }
.numeric-fluid .btn-numeric-15 { width:12.2%; height:70px; font-size:4pt !important; }


/* HIDE OUTLINES */
.btn:focus, .btn:focus:active, .active.btn:focus, .focus.btn, .focus.btn:active, .focus.active.btn,
a:focus {
  outline:none;
  -moz-outline-style:none;
}

.header {background: #99001a url("../images/deburen_graphics/banner.png") no-repeat center top;}

.header .pretitle { color:white; font-size:80px; font-weight:700; letter-spacing:-2px; }
.header .title { color:white; font-size:130px; font-weight:700; line-height:100px; letter-spacing:-5px; }
.header .subtitle { color:white; font-size:28px; font-weight:700; line-height:10px; letter-spacing:12px; margin-right:18px; }

.bubble {
  z-index:3;
  position:absolute;
  font-size:48px;
  /*font-weight:700;*/
  line-height:120%;
  min-width:350px;
  /*width: 270px;
  height: 120px;*/
  padding: 30px 40px;
  background: #FFFFFF;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 25px;
  /*border: #7F7F7F solid 1px;*/

  -webkit-box-shadow: -4px 4px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow:    -4px 4px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow:         -4px 4px 5px 0px rgba(50, 50, 50, 0.75);
}

.bubble:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 31px 18px 0;
  border-color: #FFFFFF transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -31px;
  left: 200px;
}

.bubble:before {
  content: '';
  position: absolute;
  /*border-style: solid;*/
  border-width: 31px 18px 0;
  border-color: #fff transparent;
  display: block;
  width: 0;
  z-index: 0;
  bottom: -32px;
  left: 200px;
}

.btm-right-in:after{
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 100px;
  bottom: -45px;
  border: 12px solid;
  border-width: 30px;
  border-color: white white transparent transparent;
}

.input-disabled {color:#A9A9A9 !important;}
.input-receive{height: 60px; font-size: 28px; color: #000; border-radius: 18px;}
.input-deliver{height: 73px; font-size: 28px; color: #000; border-radius: 18px;}

.flip-horizontal { transform: scaleX(-1); filter: FlipH; -ms-filter: "FlipH";  }

/*.emboss {*/
/*text-shadow: 0 -4px 3px rgba(255, 255, 255, 0.3), 0 3px 4px rgba(0, 0, 0, 0.2);*/
/*color: #d7dee1;*/
/*}*/
.emboss {
  color: white;
  text-shadow: 0 1px 0 #ccc,
  0 2px 0 #c9c9c9,
  0 3px 0 #bbb,
  0 4px 0 #b9b9b9,
  0 5px 0 #aaa,
  0 6px 1px rgba(0,0,0,.1),
  0 0 5px rgba(0,0,0,.1),
  0 1px 3px rgba(0,0,0,.3),
  0 3px 5px rgba(0,0,0,.2),
  0 5px 10px rgba(0,0,0,.25),
  0 10px 10px rgba(0,0,0,.2),
  0 20px 20px rgba(0,0,0,.15);
}

.top5 { margin-top:5px; }
.top7 { margin-top:7px; }
.top10 { margin-top:10px; }
.top15 { margin-top:15px; }
.top17 { margin-top:17px; }
.top30 { margin-top:30px; }
.top60 { margin-top:60px; }
.top120 { margin-top:120px; }

small { font-size: 50%; }

p.size1 { font-size: 26px; line-height: 30px; }
p.size2 {  }
p.size3 {  }
p.size4 {  }
p.size5 {  }

.italic { font-style: italic; }
.red { color: #99001A; font-family: 'Volkswagen-MediumIta', "Nunito"; font-size:30px;  }
.accent { color: #99001A; font-family: 'Volkswagen-Bold' }
.accentsmall { color: #99001A; }

.numeric {border-radius: 15px 15px 15px 15px; width: 70px; float: left;
  position: relative; height: 70px; margin-left: -5px; border-style: none !important; box-shadow: none !important; text-align: center; font-size: 42px}

.numeric-sweep { width: 100%; }

.dot{height: 75px; line-height: 85px; font-size: 72px; margin-left: 5px; margin-right: 10px; width: 15px }


/*Cubee design*/

.cubee-design{
  .header {
    background: #fff url("../images/deburen_graphics/header_be_6_1.png") no-repeat center top!important; height: 200px!important;
  }
  #main{
    background-color: white!important;
  }

  .show-bighead{ display: none; }

  .btn-buren1 {
    text-align:left;
    background-color:#da0719!important;
    color:white;
    border-radius:20px;
    box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
    inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
    width: 80%;
    padding: 15px 15px 15px 30px;
    margin-bottom: 15px;
    font-family: 'Volkswagen-DemiBold', "Nunito";
  }

  .btn-buren2 {
    text-align:left;
    background-color:#da0719!important;
    color:white;
    border-radius:20px;
    box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
    inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
    margin-left: 25px;
  }

  .btn-footer-left {
    text-align:left;
    background-color:#da0719!important;
    color:white;
    border-radius:20px;
    box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
    inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
    margin-right: 25px;
    margin-top: -15px;
  }

  .btn-footer-right {
    text-align:left;
    background-color:#da0719!important;
    color:white;
    border-radius:20px;
    box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
    inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
    margin-left: 25px;
    margin-top: -15px;
  }

  .btn-footer-left .glyphicon, .btn-footer-right .glyphicon {
    top:14px;
  }

  .btn-buren3 {
    text-align:left;
    background-color:#da0719!important;
    color:white;
    border-radius:15px;
    box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
    inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
  }

  .btn-buren4 {
    text-align:left;
    background-color:#da0719!important;
    color:white;
    border-radius:20px!important;
    box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
    inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
  }

  .btn-buren5 {
    text-align:center;
    background-color:#da0719!important;
    color:white;
    border-radius:20px;
    box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/
    inset 0px 2px 2px rgba(255,255,255,0.8); /*top internal highlight*/
    width: 100%;
    padding: 15px 15px 15px 15px;
    margin-bottom: 15px;
    font-family: 'Volkswagen-DemiBold', "Nunito";
  }

  .btn-buren1 .glyphicon,
  .btn-buren2 .glyphicon,
  .btn-buren3 .glyphicon,
  .btn-buren4 .glyphicon {
    top:12px;
  }

  footerburen{ background: #FFF!important;}

  .breadcrumbdb {
    background-color: #8e9293!important;
    /*border: 1px solid #af001e!important;*/
    border: none!important;
    margin-top: 2px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .breadcrumbdb li a {
    background-color: #8e9293!important;
  }

  .breadcrumbdb li a::before {
    border-left: 30px solid #fff!important;
    margin-top: -52px!important;
  }

  .breadcrumbdb li a::after {
    border-left: 30px solid #8e9293!important;
    margin-top: -52px!important;
    margin-left: -1px!important;
  }

  .breadcrumbdb li:last-child a::before {
    border-left: 30px solid #8e9293!important;
    margin-top: -52px!important;
  }

  .breadcrumbdb li a.active {
    background: #646363 none repeat scroll 0 0!important;
  }

  .breadcrumbdb li a.active::before {
    border-left: 30px solid #fff!important;
    margin-top: -52px!important;
  }

  .breadcrumbdb li a.active::after {
    border-left: 30px solid #646363!important;
    margin-top: -52px!important;
    margin-left: -1px!important;
  }

  .breadcrumbdb li:first-child a::after {
    border-bottom: 51px solid transparent!important;
    border-top: -1px solid transparent!important;
  }

  .breadcrumbdb li:first-child a.active::before {
    border-left: 31px solid #fff!important;
    margin-top: -50px!important;
  }

  .breadcrumbdb li:first-child a.active::after {
    border-bottom: 52px solid transparent!important;
    border-left: 30px solid #475155;
    border-top: 52px solid transparent;
  }

  .helpbutton {
    width: 121px;
    height: 121px;
    border-radius: 50%;
    background-color: #da0719;
    border: 4px solid #fff;
    box-shadow: 0 0 0 5px #da0719;
    -ms-transform: rotate(-35deg); /* IE 9 */
    -webkit-transform: rotate(-35deg); /* Chrome, Safari, Opera */
    transform: rotate(-35deg);
    padding: 18px;
    font-size: 52px;
    color: #FFF;
    cursor: pointer;
  }

  #language{
    right: 5% !important;
    visibility: visible;
  }

  #language a{
    border-color: #c6c6c6;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    font-size: 32px;
    color: #999999;
    padding: 10px;
  }

  #language a.active {
    /*box-shadow: 0px 10px 10px 2px #c6c6c6;*/
    border-color: #c6c6c6;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    /*border-width: 8px 2px 10px;*/
    background: #c6c6c6;
    font-size: 32px;
    color: #fff;
    padding: 10px;
  }

  h1.title-buren1{
    font-size: 38px;
  }

  #scan_customercode{ font-size: 18px!important;}
  label.countrycode, label.mobilenr, label.customercode{ font-size: 28px!important; }

}